import { render, staticRenderFns } from "./ShimingAuth.vue?vue&type=template&id=5dbf2251&scoped=true&"
import script from "./ShimingAuth.vue?vue&type=script&lang=js&"
export * from "./ShimingAuth.vue?vue&type=script&lang=js&"
import style0 from "./ShimingAuth.vue?vue&type=style&index=0&id=5dbf2251&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dbf2251",
  null
  
)

export default component.exports